import decode from 'jwt-decode';


export default class AuthHelperMethods {
    
    loggedIn = () => {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken() // Getting token from localstorage
        //history.replace('/t');
        return !!token && !this.isTokenExpired(token) // handwaiving here
    }

    isTokenExpired = (token) => {
        try {
            const decoded = decode(token);
            if (decoded.exp < Date.now() / 1000) { // Checking if token is expired.
                return true;
            }
            else
                return false;
        }
        catch (err) {
            console.log("expired check failed! Line 42: AuthService.js");
            return false;
        }
    }

    setToken = (idToken) => {
        // Saves user token to localStorage
        localStorage.setItem('token_localStorage', idToken)
    }

    setTokenRefesh = (idToken) => {
        // Saves user token to localStorage
        localStorage.setItem('tokenRefesh_localStorage', idToken)
    }

    getToken = () => {
        // Retrieves the user token from localStorage
        //if(localStorage.getItem('token_localStorage'))
            return localStorage.getItem('token_localStorage')
        //else
           // return null
    }

    checkLogin = () => {
        return localStorage.getItem('token_localStorage')
    }

    checkLogin_permission = () => {
        if (localStorage.getItem("token_localStorage") === null) {
            return false;
        }else{
            if(this.getConfirm().result.lev_qt === true && this.getConfirm().result.cus_log === false){
                return true
            }else{
                return false
            }
            //return localStorage.getItem('token_localStorage')
        }
    }

    checkLogin_permissionShipment = () => {
        if (localStorage.getItem("token_localStorage") === null) {
            return false;
        }else{
            if(this.getConfirm().result.lev_qt === false && this.getConfirm().result.cus_log === true){
                return true
            }else{
                return false
            }
        }
    }

    logout = () => {
        // Clear user token and profile data from localStorage
        //token = localStrorage || ""
        localStorage.removeItem('token_localStorage');
        localStorage.removeItem('tokenRefesh_localStorage');
    }

    getConfirm = () => {
        // Using jwt-decode npm package to decode the token
        let answer = null
        if(this.getToken() !== null){
            answer = decode(this.getToken());
        }
        return answer;
    }

    _checkStatus = (response) => {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response
        } else {
            var error = new Error(response.statusText)
            error.response = response
            throw error
        }
    }

    check_Auth = (module = null, action_method = null) => {
        if (!this.loggedIn()){
          window.location.replace("/" + process.env.ADMIN_URL+"/login");
        }
        const token = this.getConfirm();
        let lev = token.result.lev;
        let action_moid = token.result.action_moid;
        let is_module_name = token.result.is_module_name;
        //console.log(is_module_name)
        let moname_ar = [];
        let modid_array = [];
        if(is_module_name){
            moname_ar = is_module_name.split(",");
        }
        if(action_moid !== null)
            modid_array = action_moid.split(",");

        if(lev === '1'){
            return true
        }else{
            //return true;
            if(module !== null){
                if(moname_ar.indexOf(module) > -1 === true){
                    if(modid_array !== ''){
                        if(modid_array.indexOf(module + '-' + action_method) > -1 === true || lev === '1'){
                            return true
                        }else{
                            return false
                        }
                    }else{
                        return false
                    }
                }else{
                    return false
                }
            }else{
                return false
            }
        }
        

    }

    auth_Module = (module, action_method) => {
        if(this.check_Auth(module, action_method) === false){
            //history.replace('404page');
            window.location.replace("/404page");
        }
    }
}