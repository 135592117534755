import React from 'react'
import { Route, Switch } from 'react-router-dom'
import PrivateRoute from '../PrivateRoute'
import RestrictedRoute from '../RestrictedRoute'
import PrivateWebRoute from '../PrivateWebRoute'
import PublicWebRoute from '../PublicWebRoute'

const AppRoutes = ({ routes }) => {
  return (
    <Switch>
      {routes.map((route, k) => {
        if (route.routes) {
          return (
            <Route
              key={`${route.path.join()},${k}`}
              path={route.path}
              exact={route.exact}
            >
              <route.component>
                <AppRoutes routes={route.routes} />
              </route.component>
            </Route>
          )
        }

        if (route.requiredAuth) {
          return <PrivateRoute key={route.path} path={route.path} exact={route.exact} component={route.component} routes={route.routes}/>
        }

        if (route.requiredCus) {
          return <PrivateWebRoute key={route.path} path={route.path} exact={route.exact} component={route.component} routes={route.routes}/>
        }

        if (route.restricted) {
          return <RestrictedRoute key={route.path} path={route.path} exact={route.exact} component={route.component} />
        }

        if (route.web) {
          return <PublicWebRoute key={route.path} path={route.path} exact={route.exact} component={route.component} />
        }

        return <Route key={route.path} path={route.path} exact={route.exact} component={route.component} />
      })}
    </Switch>
  )
}

export default AppRoutes
