import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { hasAuth, checkPermission } from './permission'
import UnauthorizedPage from '../pages/Error/Unauthorized'

const PrivateRoute = ({ userInfo, component: Component, ...rest }) => {
  if (hasAuth(userInfo)) {
    if (checkPermission()) {
      return <Route {...rest} render={() => <Component />} />
    }

    return <Route {...rest} render={() => <UnauthorizedPage />} />
  }

  return <Route {...rest} render={(props) => <Redirect to={{ pathname: '/admin/login', state: { from: props.location } }} />} />
}


export default PrivateRoute
