import LoginPage from '../../pages/admin/Users/Login'

const authRoutes = [
  {
    path: '/admin/login',
    exact: true,
    restricted: true,
    component: LoginPage,
  }
]

export default authRoutes
