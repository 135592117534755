import React, { memo } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { hasAuth } from './permission'
//import { createStructuredSelector } from 'reselect'
//import { connect } from 'react-redux'
//import { user } from '../store/modules/auth/selectors'

const RestrictedRoute = ({ userInfo, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      hasAuth(userInfo) ? (
        <Redirect to={{
          pathname: '/',
          state: { from: props.location },
        }}
        />
      ) : <Route {...rest} render={() => <Component />} />
    )}
  />
)


export default RestrictedRoute
