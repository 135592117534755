import Loadable from 'react-loadable'

import WebLayout from '../../components/web/layout/WebLayout'
import LoadingPage from '../../components/web/layout/Loading'


const PageMain = Loadable({
  loader: () => import('../../pages/web/Actionmachi'),
  loading: LoadingPage,
})





const ActionmachiRoutes = [
  {
    path: '/actionmachi',
    exact: true,
    web: true,
    component: PageMain,
    layout: WebLayout,
  },
  {
    path: '/actionmachi/:code',
    exact: true,
    web: true,
    component: PageMain,
    layout: WebLayout,
  }
]

export default ActionmachiRoutes
