//import PageNotFound from '../pages/Error/NotFound'
import PageNotFound from '../pages/Error/Page404'
import GuestLayout from '../components/admin/layout/GuestLayout'
import MainLayout from '../components/admin/layout/MainLayout'
import WebLayout from '../components/web/layout/WebLayout'

/**
 * Get List Route Modules.
 *
 * @returns {[]}
 */
const publicRoutes = require.context('./public', true, /\.js$/)
const privateRoutes = require.context('./private', true, /\.js$/)
const privateWebRoutes = require.context('./privateWeb', true, /\.js$/)
const publicWebRoutes = require.context('./publicWeb', true, /\.js$/)

const parseModules = (context) => {
  const routes = []
  const paths = []

  context.keys().forEach((fileName) => {
    const object = context(fileName).default
    routes.push(...object)
    paths.push(...object.map((o) => o.path))
  })

  return {
    routes,
    paths,
  }
}

const routes = [
  {
    path: parseModules(publicRoutes).paths,
    exact: true,
    requiredAuth: false,
    requiredCus: false,
    restricted: true,
    component: GuestLayout,
    routes: parseModules(publicRoutes).routes,
  },
  {
    path: parseModules(privateRoutes).paths,
    exact: true,
    requiredAuth: true,
    requiredCus: false,
    restricted: false,
    component: MainLayout,
    routes: parseModules(privateRoutes).routes,
  },
  {
    path: parseModules(privateWebRoutes).paths,
    exact: true,
    requiredAuth: true,
    requiredCus: false,
    restricted: false,
    component: WebLayout,
    routes: parseModules(privateWebRoutes).routes,
  },
  {
    path: parseModules(publicWebRoutes).paths,
    exact: true,
    requiredAuth: false,
    requiredCus: false,
    restricted: true,
    component: WebLayout,
    routes: parseModules(publicWebRoutes).routes,
  },
  {
    path: '*',
    component: PageNotFound,
  },
]

export default routes
