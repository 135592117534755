import Loadable from 'react-loadable'

import WebLayout from '../../components/web/layout/WebLayout'
import LoadingPage from '../../components/web/layout/Loading'


const PageMain = Loadable({
  loader: () => import('../../pages/web/Home/Home'),
  loading: LoadingPage,
})

const PageLogin = Loadable({
    loader: () => import('../../pages/web/Users/Login'),
    loading: LoadingPage,
  })



  const PageNoti = Loadable({
    loader: () => import('../../pages/web/Home/Noti'),
    loading: LoadingPage,
  })




const WebRoutes = [
  {
    path: '/',
    exact: true,
    web: true,
    component: PageMain,
    layout: WebLayout,
  },
  {
    path: '/login',
    exact: true,
    web: true,
    component: PageLogin,
    layout: WebLayout,
  },
  {
    path: '/noti',
    exact: true,
    web: true,
    component: PageNoti,
    layout: WebLayout,
  }
]

export default WebRoutes
