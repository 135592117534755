import Loadable from 'react-loadable'

import WebLayout from '../../components/web/layout/WebLayout'
import LoadingPage from '../../components/web/layout/Loading'


const PageMain = Loadable({
  loader: () => import('../../pages/web/Home/UpdateVersion'),
  loading: LoadingPage,
})






const UpdateVersionRoutes = [
  {
    path: '/updateversion',
    exact: true,
    web: true,
    component: PageMain,
    layout: WebLayout,
  }
]

export default UpdateVersionRoutes
