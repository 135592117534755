import Loadable from 'react-loadable'

import WebLayout from '../../components/web/layout/WebLayout'
import LoadingPage from '../../components/web/layout/Loading'


const PageMain = Loadable({
  loader: () => import('../../pages/web/ScanQrcode'),
  loading: LoadingPage,
})

const PageMain1 = Loadable({
  loader: () => import('../../pages/web/ScanQrcode/index1'),
  loading: LoadingPage,
})

const PageMainScan = Loadable({
  loader: () => import('../../pages/web/ScanQrcode/QRscanner'),
  loading: LoadingPage,
})







const ActionmachiRoutes = [
  {
    path: '/scanqrcode',
    exact: true,
    web: true,
    component: PageMain,
    layout: WebLayout,
  },
  {
    path: '/scanqrcode1',
    exact: true,
    web: true,
    component: PageMain1,
    layout: WebLayout,
  },
  {
    path: '/qrcanner',
    exact: true,
    web: true,
    component: PageMainScan,
    layout: WebLayout,
  }
]

export default ActionmachiRoutes
